import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {
  public serviceBase: any;
  // public serviceBase: any;
  public imageBase: any;
  public taskImageBase: any;
  public webBase: any;
  public auth_token: any;
  public loadingShow: boolean = false;
  public progressLoadingShow: boolean = false;
  public progressLoadingValue: number = 0;
  user: any = JSON.parse(sessionStorage.getItem("user"));
  baseURl:string="https://api.learningbix.com:8112/"

  //vendorDataService:any=  new BehaviorSubject<Object>(JSON.parse(sessionStorage.getItem("user")));

  constructor(private router: Router, private http: HttpClient, private toastrService: ToastService) {
    // this.user= JSON.parse(sessionStorage.getItem('user'))

    // this.serviceBase = 'http://floweralley.in:8222/';//'http://3.15.167.54:8117/',//'http://3.22.55.178:3000/';
    // this.serviceBase = 'http://65.1.216.81:8222/';
    // this.imageBase = 'http://65.1.216.81/api/uploads/';
    this.serviceBase="https://api.learningbix.com:8112/"
    this.imageBase = 'https://learningbixstorage098.blob.core.windows.net/learningbixcom/';


    // this.serviceBase="https://api.learningbix.com:8112/"

  }


  createAuthorizationHeader(headers: HttpHeaders) {
    if (this.auth_token) {
      headers.append('Token', this.auth_token);
    }
    headers.append('Content-Type', 'application/json');
  }


  get(url) {
    //let headers = new HttpHeaders({'Content-Type':'application/json'});
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.user.token,
        'Content-Type': 'application/json'
      }
    );
    //this.createAuthorizationHeader(headers);
    return this.http.get(this.serviceBase + url, {
      headers: headers

    });
  }

  goTo(page) {
    this.router.navigate([page]);
  }

  post(url, data) {
    if(data!=null)
      data.isAdmin=1;
    else
      data={isAdmin:1}
    if (this.user != undefined && this.user != null) {
      let headers = new HttpHeaders(
        {
          // 'Authorization': 'Bearer ' + this.user.token,
          'Content-Type': 'application/json'
        });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });

    }
  }
  Postimage(url, data) {
    if(data!=null)
      data.isAdmin=1;
    else
      data={isAdmin:1}
    if (this.user != undefined && this.user != null) {
      let headers = new HttpHeaders(
        {
          // 'Authorization': 'Bearer ' + this.user.token,
         'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });

    }
  }
  put(url, data) {

    if (this.user != undefined && this.user != null) {
      let headers = new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.user.token,
          'Content-Type': 'application/json'
        });
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });

    }
  }
  delete(url, data) {
    if (this.user != undefined && this.user != null) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.user.token,
          'Content-Type': 'application/json'
        }),
        body: data
      };
      return this.http.delete(this.serviceBase + url, httpOptions);
    } else {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json'
        });
      return this.http.delete(this.serviceBase + url, data);

    }
  }

  post1(url) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.serviceBase + url, {
      headers: headers
    });

  }
  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('attachment', fileToUpload, fileToUpload.name);
    return this.http.post(this.baseURl + 'upload_file', formData)
  }
  postFiles(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('attachment', fileToUpload, fileToUpload.name);
    return this.http.post("https://api.learningbix.com:8112/" + 'upload_file', formData)
  }

  postdata(url, user) {
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.user.token,
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    let httpParams = new HttpParams();
    Object.keys(user).forEach(function (key) {
      httpParams = httpParams.append(key, user[key]);
    });
    return this.http.post(this.serviceBase + url, httpParams, { headers: headers });

  }
  public formPost(url, formData) {
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.user.token,

      });
    return this.http.post<any>(this.serviceBase + url, formData, {
      headers: headers
    });
  }
  public formPut(url, formData) {
    let headers = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.user.token,

      });
    return this.http.put<any>(this.serviceBase + url, formData, {
      headers: headers
    });
  }


  showSuccess(message) {
    this.toastrService.success(message);
  }

  showError(message) {
    this.toastrService.error(message);
  }

  showInfo(message) {
    this.toastrService.info(message);
  }

  showWarning(message) {
    this.toastrService.warning(message);
  }

  downloadCSV(data, fileName) {
    var csvData = this.ConvertToCSV(data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }


}