import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

import { AuthGuardService } from './services/auth-guard.service';
const routes: Routes = [
  // { path: 'admin', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
  { path: '', loadChildren: () => import('./authencation/authencation.module').then(m => m.AuthencationModule) },

  {
    path: '', 
    canActivate: [AuthGuardService],
    component: LayoutComponent, 
    children: [
      {
        path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },{
        path: 'age-group', loadChildren: () => import('./modules/age-group.module').then(m => m.AgeGroupModule)
      },{
        path: 'product', loadChildren: () => import('./modules/product.module').then(m => m.ProductModule)
      },
      { path: 'common', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
      { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
      { path: 'setting', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'driver', loadChildren: () => import('./driver/driver.module').then(m => m.DriverModule) },
      { path: 'shipments', loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule) },
      { path: 'supply', loadChildren: () => import('./supply/supply.module').then(m => m.SupplyModule) },
      { path: 'coupon', loadChildren: () => import('./coupon/coupon.module').then(m => m.CouponModule) },
      { path: 'testimonials', loadChildren: () => import('./testimonials/testimonials.module').then(m => m.TestimonialsModule) }
    ]
  },

]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
