<div id="main-wrapper">
    <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
            <div class="nav-bar-item" id="top-without-side">

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-6 text-left">

                            <a *ngIf="!showtoggle" (click)="toggleHide()"><i class="fas fa-bars"></i></a>
                            <a *ngIf="showtoggle" (click)="toggleShow()"><i class="fas fa-bars"></i></a>
                        </div>
                        <div class="col-6 text-right right-nav-list">
                            <ul>
                                <!-- <li>
                                    <a href="javascript:void()">
                                        <i class="fas fa-cog"></i>
                                    </a>
                                </li> -->
                                <li>
                                    <a href="javascript:void(0);" (click)="onLogout()">
                                        <i class="fas fa-sign-out-alt m-icon"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </nav>

    </header>
    <aside class="left-sidebar" id="sidebar">
        <div class="scroll-sidebar ps">
            <div class="profile">
                <div>
                    <h1>LearningBix</h1>
                    <!-- <img src="http://eliteadmin.themedesigner.in/demos/bt4/assets/images/logo-text.png"> -->
                </div>
                <div>
                </div>
            </div>
            <ul class="sidebar-nav scrol-side">
                <ng-container *ngIf="Admin_Menu">
                    <li class="sidenav-item" (click)="menuClick(i+1);" id="menu-{{i+1}}"
                        *ngFor="let menu_data of Menus;let i=index"
                        [ngClass]="maintitle == menu_data.title ? 'active':''">
                        <a href="javascript:void(0)" (click)="toggelmenu('dashboard')" routerLink="/{{menu_data.link}}">
                            <i class="{{menu_data.icon}} m-icon"></i>{{menu_data.name}}</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="Vendor_Menu">
                    <li class="sidenav-item" (click)="menuClick(i+1);" id="menu-{{i+1}}"
                        *ngFor="let menu_data of Menus_Vendor;let i=index"
                        [ngClass]="maintitle == menu_data.title ? 'active':''">
                        <a href="javascript:void(0)" (click)="toggelmenu('dashboard')" routerLink="/{{menu_data.link}}">
                            <i class="{{menu_data.icon}} m-icon"></i>{{menu_data.name}}</a>
                    </li>
                </ng-container>
                <li>
                    <a href="javascript:void(0)" (click)="toggelmenu('customer')"><i
                            class="fas fa-user m-icon"></i>Setting
                        <span class="right-arrow"><i class="fas fa-angle-right"></i></span>
                    </a>
                    <ul *ngIf="customer">
                        <li>
                            <a href="javascript:void(0)" [routerLink]="['/setting/change-pass']">Change Password</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="onLogout()"><i
                            class="fas fa-sign-out-alt m-icon"></i>logout</a>
                </li>
            </ul>

        </div>
    </aside>
    <div class="page-wrapper" id="pageWrapper">
        <div class="container-fluid">
            <div class="row page-titles" *ngIf="subtitle">
                <div class="col-md-5 align-self-center" id="breadcrumbsubtitle">
                    <!-- <h4 class="breadcrumb-main">
                        <span *ngIf="(subtitle == 'dashboard') || (subtitle == 'coupon') ">{{subtitle}}</span>
                        <span *ngIf="subtitle != 'dashboard'">{{maintitle}}</span>
                    </h4> -->
                </div>
                <div class="col-md-7 align-self-center text-right" id="breadcrumbmaintitle">
                    <div class="d-flex justify-content-end align-items-center">
                        <mdb-breadcrumb customClass="black-text">
                            <mdb-breadcrumb-item>
                                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> {{subtitle}}
                            </mdb-breadcrumb-item>
                            <mdb-breadcrumb-item *ngIf="subtitle != 'dashboard'">
                                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> {{maintitle}}
                            </mdb-breadcrumb-item>
                            <!-- <mdb-breadcrumb-item class="active">
                                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> Data
                            </mdb-breadcrumb-item> -->
                        </mdb-breadcrumb>

                    </div>
                </div>
            </div>
            <main>
                <router-outlet></router-outlet>

            </main>
        </div>
    </div>
</div>