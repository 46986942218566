<div id="main-wrapper">
    <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
           madan
        </nav>
    </header>
    <aside class="left-sidebar">
        <div class="scroll-sidebar ps">

            
        </div>
    </aside>
    <div class="page-wrapper">
        <div class="container-fluid">
         <router-outlet></router-outlet>
        </div>
    </div>
 </div>