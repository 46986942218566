import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router
  ) {}
  canActivate(): boolean {
    if (!sessionStorage.getItem('user')) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
