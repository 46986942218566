import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
import { HttpClient } from '@angular/common/http';
import { BaseServiceService } from '../services/base-service.service';

@Injectable()
export class AuthService {
  public user = JSON.parse(sessionStorage.getItem("user"));
  

  public loggedIn = new BehaviorSubject<Object>(JSON.parse(sessionStorage.getItem("user")));
  baseURl:string="https://api.learningbix.com:8112/"
  
  public vendorDataService: any = new BehaviorSubject<Object>(JSON.parse(sessionStorage.getItem("vendorDetails")));
  constructor(private http:HttpClient, private router: Router, public _service: BaseServiceService) {
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  

  login(user: User) {
    var self = this;
    if (user.email !== '' && user.password != '') {
      var sendRequestData = user;
      this._service.loadingShow = true;
      self.http.post(this.baseURl+'admin_login', sendRequestData).subscribe((res: any) => {
        self._service.loadingShow = false;
        if (res.replyCode=='success') {
          self._service.showSuccess('Login Successfully');
          self.router.navigate(['/dashboard']);
            sessionStorage.setItem("user", JSON.stringify(res));
            self.loggedIn.next(res.data);
        }
        else {
        self._service.loadingShow = false;
          self._service.showError(res.replyMsg);
        }
      }
        , error => {
          self._service.loadingShow = false;
          self._service.showError("Oops! Something went wrong!");
        });

    }
  }

  logout() {
    this.loggedIn.next(null);
    sessionStorage.removeItem("user");
    sessionStorage.removeItem('usertemp');
    this.router.navigate(['/']);

  }
  
}
