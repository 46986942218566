import { Component, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { BaseServiceService } from './services/base-service.service';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fs-admin';
  isLoggedIn$: Observable<object>;
  constructor(private authService: AuthService, private router: Router, public  httpClient: BaseServiceService, 
    private mdbSpinningPreloader: MDBSpinningPreloader, private cdRef : ChangeDetectorRef) {
  	
  	// this.userData = JSON.parse(sessionStorage.getItem("user"));
  	//console.log("userData", this.userData);

  }
  ngOnInit() {
    this.mdbSpinningPreloader.stop();
    this.isLoggedIn$ = this.authService.isLoggedIn;
 }
 ngAfterViewChecked() {
  
   this.cdRef.detectChanges();
 }
}
