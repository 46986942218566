import { Component, OnInit } from '@angular/core';
import $ from "jquery";
import { AuthService } from '../auth/auth.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  admin: boolean = false;
  emp: boolean = false;
  role: boolean = false;
  customer: boolean = false;
  payment: boolean = false;
  showtoggle=false;
  route: string;breadcrumbList: Array<any>;count: number;maintitle: string;subtitle: string;
  user:any;
  Vendor_Menu:boolean=false;
  Admin_Menu:boolean=false;
  Menus=[
    {link:'admin/category_list',name:'Category List',title:'category_list',icon:'fas fa-list'},
    {link:'age-group',name:'Age Group',title:'age_group_list',icon:'fas fa-list'},
    {link:'pages',name:'Pages',title:'pages-list',icon:'fas fa-list'},
    {link:'admin/product-list',name:'Product',title:'product-list',icon:'fas fa-list'},
    {link:'admin/school-list',name:'School Enquiry List',title:'school-enquiry-list',icon:'fas fa-list'},

    {link:'admin/order-list',name:'Order List',title:'order-list',icon:'fas fa-list'},
    // {link:'admin/payment-list',name:'Payment List',title:'payment-list',icon:'far fa-money-bill-alt'},
  ]
  Menus_Vendor=[
    {link:'/dashboard',name:'Dashboard',title:'dashboard',icon:'fas fa-home'},
    // {link:'admin/cataloguelist',name:'Catalogue list',title:'cataloguelist',icon:'fas fa-list'},
    {link:'admin/product-list',name:'Product List',title:'product-list',icon:'fas fa-list'},
    {link:'admin/order-list',name:'Order List',title:'order-list',icon:'fas fa-list'},
  ]
  constructor(private authService:AuthService,location: Location, router: Router) {
    router.events.subscribe((val) => {
      if (location.path()) {
        this.route = location.path();
        this.breadcrumbList = this.route.split('/');
        this.count = this.breadcrumbList.length;
        if(this.count === 2) {
          this.subtitle = this.breadcrumbList[1];
          // if(this.subtitle == 'dashboard'){
          //   this.maintitle=this.subtitle;
          // }
        }
        if( this.count > 2) {
          this.subtitle = this.breadcrumbList[1];
          this.maintitle = this.breadcrumbList[2];
        }
        // console.log('maintitle:::',this.maintitle)
      }
    });
    this.user = JSON.parse(sessionStorage.getItem('user'))
    // console.log('user:::::',this.user.data.role_id);
    if(this.user){
      if(this.user.data.role_id == 1){
        this.Admin_Menu=true;
        this.Vendor_Menu=false;
      }else{
        this.Admin_Menu=false;
        this.Vendor_Menu=true;
      }
    }
   }
   public menuClick(id){
    // if($('#menu-'+id).hasClass('active')){
    //   alert(0);
    //   $('#menu-'+id).removeClass('active');
    //   // $('body').removeClass('layout-expanded');
    // }else{
      // alert(1);
      $('.sidenav-item').removeClass('active');
      $('#menu-'+id).addClass('active');
      // $('body').removeClass('layout-expanded');
    // }

  }
  ngOnInit(): void {
  }
  toggelmenu(value) {
    if (value == 'dashboard') {
      this.admin = false;
      this.emp = false;
      this.role = false;
      this.customer = false;
      this.payment = false;
    }
    if (value == 'admin') {
      this.admin = !this.admin;
      this.emp = false;
      this.role = false;
      this.customer = false;
      this.payment = false;
    }
    if (value == 'emp') {
      this.emp = !this.emp;
      this.admin = false;
      this.role = false;
      this.customer = false;
      this.payment = false;
    }
    if (value == 'role') {
      this.role = !this.role;
      this.admin = false;
      this.emp = false;
      this.customer = false;
      this.payment = false;
    }
    if (value == 'customer') {
      this.customer = !this.customer;
      this.admin = false;
      this.emp = false;
      this.role = false;
      this.payment = false;
    }
    if (value == 'payment') {
      this.payment = !this.payment;
      this.admin = false;
      this.emp = false;
      this.role = false;
      this.customer = false;
    }

  }
  toggleHide(){
    $("#sidebar").removeClass("left-sidebar")
    $("#sidebar").addClass("with-out-side")
    $("#pageWrapper").removeClass("page-wrapper")
    $("#pageWrapper").addClass("without-sidebar")
    $("#top-without-side").removeClass("nav-bar-item")
    this.showtoggle= true
  }
  toggleShow(){
    $("#sidebar").addClass("left-sidebar")
    $("#sidebar").removeClass("with-out-side")
    $("#pageWrapper").addClass("page-wrapper")
    $("#pageWrapper").removeClass("without-sidebar")
    $("#top-without-side").addClass("nav-bar-item")
    this.showtoggle= false
  }
  logout(){

  }
  onLogout() {
    this.authService.logout();
  }
}
